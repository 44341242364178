import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./privacyTermsThemes.module.css"
import queryString from "query-string"

const PrivacyPage = (props) => {
  
    // let top_margin = null;
    // let backgroundStyle = null;
    let from_app = true;
    if (typeof window !== "undefined") {
        const search = window.location.search;
        const params = queryString.parse(search);
        from_app = params.from_app !== undefined && parseInt(params.from_app) === 1;
        // top_margin = from_app ? "5%" : "14%";
        // backgroundStyle = from_app ? {backgroundImage: "none"} : {backgroundImage: background};
    }

  return (
    <Layout>
        <SEO title="Paths Privacy" />
        <div className={from_app ? `${styles.privacyTermsContainer} ${styles.privacyTermsContainerFromApp}` : styles.privacyTermsContainer}>
            <div className={from_app ? `${styles.privacyTermsContent} ${styles.privacyTermsContentFromApp}` : styles.privacyTermsContent}>
                <h3>Paths Privacy Policy</h3>
                <p>
                    Paths Game Limited ("we," "us" or "Paths") are firmly committed to privacy. This Privacy Policy (the “Policy”) 
                    describes the ways we collect, store, use, and manage the information, including personal information, that 
                    you provide or we collect in connection with our mobile application  “Paths”, website located at <a href="http://pathsapp.co">http://pathsapp.co</a>, 
                    (collectively, together with any other websites controlled by Paths Game Limited, the “Website”) or any Paths game or 
                    application provided on other websites or other third party sources (collectively, the “Service”).
                </p>
                <p>
                    By installing, using or otherwise accessing the Service, you accept this Privacy Policy and agree to the terms, 
                    conditions and notices contained or referenced herein. We reserve the right to make changes to this Privacy Policy 
                    at any time by posting a revised Policy on this website and emailing a copy of the revised Policy to members without 
                    prior notice and such changes become effective once a revised Privacy Policy is posted on our Website. Your continued 
                    use of our Website constitutes your acceptance of such changes. Accordingly, you should review this Privacy Policy from 
                    time to time for changes. 
                </p>
                <p>
                    The protection of your privacy is very important and we have adopted the following practices to safeguard the confidentiality 
                    of your personal information.

                </p>
                <h4>Information collected and used</h4>
                <p>
                    We collect your information for the purpose of providing and enhancing our Service and to enable you to 
                    easily navigate and enjoy your use of our Service. We use your information to register your account, 
                    provide customer support, resolve disputes, collect fees, connect users, and notify you of updates to 
                    the game (or of other/new games) and special promotions.
                </p>
                <h6>iOS or Android Platforms</h6>
                <p>
                    We automatically collect and record certain information when you use any of our games or applications, 
                    such as your unique device ID, MAC address, advertising identifier, OpenUDID, IMEI number, hardware type, 
                    the version of your operating system (“OS”), your in-game activity, and your location (based on your Internet 
                    Protocol (“IP”) address). 
                </p>
                <p>
                    We collect this information as it is useful to us for troubleshooting, and helps us understand usage trends. 
                    We may also use this information to contact you with in-game technical notices, updates, security alerts and 
                    support and administrative messages. We may also use this information to customize the user experience and 
                    promotions, enforce our terms of service, and make recommendations about user connections.
                </p>
                <p>
                    We may give users the opportunity to opt-in to location-based services, which rely upon a device’s GPS coordinates. 
                    A user’s location may be used to suggest friends with whom they might want to connect, analyze usage trends or 
                    target certain promotions. You may opt-out of these location services via the settings on your device.
                </p>
                <h6>Facebook Login</h6>
                <p>
                    By logging into our Service through Facebook Login, we may collect information that is available via your Facebook 
                    account or the Facebook API, including your email, your public profile and your list of Facebook friends. This 
                    information will be primarily used for user registration and social features, which may include friend-to-friend 
                    or user-to-user interaction, chat, voice or messaging functionality, public leaderboards, head-to-head competition 
                    and other similar features. The information provided may also be used to analyze usage trends, contact you about 
                    your experience with the Service, and offer you certain of our features, games or promotions that may be of 
                    particular interest to you.
                </p>
                <h6>Platforms/Third-Party Marketplaces</h6>
                <p>
                    By providing our Service on third party platforms/marketplaces, such as Apple’s App Store, the 
                    platforms/marketplaces may collect information about you or receive information from us in order for 
                    the platforms/marketplaces to provide or analyze their services. Please note that these platforms/marketplaces 
                    were developed and administered by people or companies not affiliated with or controlled by Paths and Paths is 
                    therefore not responsible for the actions of those people or companies. It is your responsibility to carefully 
                    review the terms of service and privacy policies of the platforms/third-party marketplaces that you use to 
                    access our Service.
                </p>
                <h6>Amazon AWS Server</h6>
                <p>
                    As Amazon AWS is our application engine service provider. Any information provided through our applications 
                    is available to Amazon, as a result of Amazon providing this service.
                </p>
                <h6>Cookies</h6>
                <p>
                    We use cookies to identify traffic that comes through advertising and to facilitate a more user friendly 
                    experience. Cookies are small pieces of information that a website places on your computer's hard drive 
                    so we know it's you when you visit. This helps us to create a personalized experience for you when you 
                    visit our site. The use of cookies is a very common practice on the Internet and provides for a more 
                    customized user experience of the Website. If you are uncomfortable with this, you can disable all cookies 
                    through your browser settings. Your enjoyment of our site won't be adversely affected if you have set your 
                    browser to reject cookies.
                </p>
                <h4>Payment</h4>
                <p>
                    Your credit card details do not get processed or transmitted through or via the Website; all payments are 
                    processed via the Apple iTunes Store or Google Play Store. The Privacy Policies of those third parties governs 
                    their use of PII provided to them (please 
                    see <a href="https://www.apple.com/legal/privacy/en-ww/">https://www.apple.com/legal/privacy/en-ww/</a> and <a href="https://play.google.com/intl/en-us_us/about/play-terms.html">https://play.google.com/intl/en-us_us/about/play-terms.html</a>).
                </p>
                <h4>Advertisers and Third-Parties</h4>
                <p>
                    We may disclose your Non-PII to third party organisations, agents and service providers to assist us in 
                    providing the Services, as well as conducting market and business research and analysis for any of the 
                    purposes set out in this Privacy Policy.
                </p>
                <p>
                    We promote third party advertisers on our Service. Clicking advertisements may redirect you to websites 
                    where the third party’s collection, use or disclosure of Non-PII or PII you provide to them is beyond our 
                    control. We will never pass personally identifying information about you to advertisers - even when you are 
                    logged in and click an advertisement.
                    <br/>
                    Different sites have very different privacy practices and policies. We recommend you locate and read the 
                    privacy policy of any site you are redirected to.
                </p>


                <h4>
                    Third-Party Social Networking Sites
                </h4>
                <p>
                    When you have enabled the use of our Services through a third-party social networking or similar site or 
                    mobile or other application (a "Social Networking Site"), such as Facebook, Google+ or Twitter, you permit 
                    Paths to access certain information about you that is made available to Paths through or from that Social 
                    Networking Site. The information obtained by Paths varies by Social Networking Site and may be affected by 
                    the privacy settings you establish at that Social Networking Site, but can include information such as your 
                    name, profile picture, network, gender, username, user ID, age range or birthday, language, location, country 
                    and other information.
                </p>
                <p>
                    By accessing or using our Services through a Social Networking Site, you are authorizing us to collect, 
                    store, retain and use, in accordance with this Policy, any and all of your information that we have obtained 
                    from the Social Networking Site, including to create a profile page and account for you on our Service. 
                    Your agreement to the foregoing takes place when you "accept" or "allow" or “go to" (or other similar terms) 
                    our application on a Social Networking Site or the transfer of information to us from such site. If there is 
                    information about your “friends" or people you are associated with in your Social Networking Site account, 
                    the information we obtain about those persons may also depend on the privacy settings such people have with 
                    the applicable Social Networking Site. 
                </p>
                <p>
                    You acknowledge and agree that Paths is not responsible for, and has no control over, any applicable privacy 
                    settings on any Social Networking Sites (including any settings related to any messages or advertisements about 
                    Paths that the Social Networking Site may send to you or your friends). You should always review, and if necessary, 
                    adjust your privacy settings on Social Networking Sites before getting or using applications such as ours or linking 
                    or connecting your Social Networking Site account to the Services. You may also unlink your Social Networking 
                    Site account from the Services by adjusting your settings on the Social Networking Site.
                </p>
                <p>
                    We may disclose your PII to respond to law enforcement requests; or where required by applicable laws, 
                    court orders, or government regulations; or to protect against misuse or unauthorized use of our site; 
                    or to protect the personal safety or property of our users or the public (among other things, this means 
                    that if you provide false information or attempt to pose as someone else, information about you may be 
                    disclosed as part of any investigation into your actions).
                </p>

                <h4>
                    Sale or Merger
                </h4>
                <p>
                    We may disclose and sell PII in connection with the sale, assignment, or other transfer of the business 
                    or a portion of the business of our Website, including a corporate merger, consolidation, restructuring, 
                    sale of assets or other corporate change of our direct or indirect parent companies that affects us.
                </p>

                <h4>
                    Do Not Track signals
                </h4>
                <p>
                    Our Website supports the Do Not Track browser setting. Do Not Track (“DNT") is a preference you can set 
                    in your Web browser to let the websites you visit know that you do not want them collecting certain information 
                    about you. For more details about Do Not Track, including how to enable or disable this preference, visit 
                    the Electronic Frontier Foundation page.
                </p>
                <p>
                    Website users who send Do Not Track signals will not experience an adverse effect on their experience with the Website.
                </p>

                <h4>
                    Security
                </h4>
                <p>
                    We treat data as an asset that must be protected against loss and unauthorized access. 
                    To safeguard the confidentiality and security of your PII, we use industry standard practices and technologies 
                    including but not limited to "firewalls", encrypted transmission via SSL (Secure Socket Layer) and strong data 
                    encryption of sensitive personal information when it is stored to disk. However, because the internet is an 
                    open network, we cannot warrant or guarantee that your PII is safe from unauthorised access or use. Non-PII 
                    and PII may be kept on computer systems operated by third parties. We will take reasonable precautions against 
                    unauthorised access and disclosure by those third parties.
                </p>
                <h4>
                    Access to PII
                </h4>
                <p>
                    Your PII remains yours. You may request access to, or the correction or deletion of, 
                    your PII that is held by us by editing that information through the registered access section of our Website 
                    or using the contact details set out at the end of this Privacy Policy and we will provide access to, delete 
                    it or correct it in accordance with our obligations under the applicable privacy legislation.
                </p>
                <h4>
                    Contact us
                </h4>
                <p>
                    If you wish to contact us about this Privacy Policy, including requesting access or amendments to your PII, 
                    you can contact us at privacy@pathsapp.co .
                </p>
            </div>
        </div>
        
    </Layout>
  )
}

export default PrivacyPage
